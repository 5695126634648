import React from 'react';

const ErrorComponent = ({ responseData }) => (
    <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
        <div>
            <h1>Error</h1>
            <p>Failed to fetch token</p>
            {responseData && (
                <div>
                    <h2>API Response:</h2>
                    <pre>{JSON.stringify(responseData, null, 2)}</pre>
                </div>
            )}
        </div>
    </div>
);

export default ErrorComponent;
