import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Assuming you are using React Router
import config from '../../env/index';
import logger from '../../common/logging/AILogger';
import { CustomProperties } from '../../common/logging/logger';
import { CommonLoggingConstants, ErrorCodes, EventCodes, GuestDetailLoggingConstants } from '../../config/loggingCodes';
import { options } from './BotOptions';
import ErrorComponent from './BotError';
import SearchChats from './SearchChats';

// Declare the global variable WebChat to inform TypeScript
declare global {
    interface Window {
        WebChat: any;
    }
}

const BotChatComponent = () => {
    const customProperties: CustomProperties = [];
    const webChatRef = useRef();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get('id') || '';
    const region = params.get('region') || 'eu';
    const time = params.get('time') || ''; // Retrieve the 'header' query parameter
    const subject = params.get('subject') || ''; // Retrieve the 'header' query parameter
    const host = params.get('host') || ''; // Retrieve the 'header' query parameter
    const [token, setToken] = useState('');
    const [error, setError] = useState(null);
    const [responseData, setResponseData] = useState(null); // State to hold API response data

    useEffect(() => {
        if (!id) {
            // If id is not present, return immediately
            return;
        }
        // Form API URL with parameters
        const apiUrl = `${config.API_BASE_URL.slice(0, -6)}/v2/signalr/guest/generatebottoken/${id}`;
        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'X-Region': region
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch token');
                }
                return response.json();
            })
            .then(data => {
                setToken(data.token);
                customProperties[GuestDetailLoggingConstants.GuestVisitUpdateDetail] = id;
                logger.logEvent(EventCodes.EV_107, `${CommonLoggingConstants.ComponentName}VirtualHost:API`, customProperties);
            })
            .catch(error => {
                const errorToLog = error && error.response && error.response.data ? error.response.data : error.message;
                logger.logError(ErrorCodes.ER_151, JSON.stringify(errorToLog), `${CommonLoggingConstants.ComponentName}VirtualHost:API`, JSON.stringify(error.stack));
                setError(error);
                // If there's a response, set it in the state
                if (error.response) {
                    setResponseData(error.response);
                }
            });
    }, [id, region]); // Fetch token on component mount

    useEffect(() => {
        if (token) {
            const webChatOptions = {
                directLine: window.WebChat.createDirectLine({
                    token: token
                }),
                userID: id,
                username: 'Web Chat User',
                locale: 'en-US',
                styleOptions: options
            };
            webChatRef.current = window.WebChat.renderWebChat(webChatOptions, document.getElementById('webchat'));
            return () => {
                if (webChatRef.current) {
                    // Dispose of Web Chat component
                }
            };
        }
    }, [token, id, options]);

    if (!id) {
        // If id is not present, render alternate sample screen
        return <SearchChats />;
    }

    if (error) {
        // Pass responseData to ErrorComponent
        return <ErrorComponent responseData={responseData} />;
    }

    return (
        <div>
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                backgroundColor: 'white',
                color: 'black',
                padding: '10px 0',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                zIndex: 1000
            }}>
                <div>
                    <span style={{ paddingLeft: 10, fontWeight: 'bold', fontSize: '17px' }}>Chatting with</span> {host}
                </div>
                <div>
                    <span style={{ paddingLeft: 10, fontWeight: 'bold', fontSize: '14px' }}>Time:</span> {time} <span style={{ fontWeight: 'bold' }}>Meeting:</span> {subject}
                </div>
            </div>
            <div id="webchat" role="main" style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 0 // Ensure it stays in the background
            }}></div>
        </div>
    );
};

export default BotChatComponent;
