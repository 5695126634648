import React, { CSSProperties, useState } from 'react';
import { TextField, PrimaryButton, DetailsList, SelectionMode, Link, Stack, Spinner } from 'office-ui-fabric-react';
import config from '../../config/config';
import { useLocation } from 'react-router-dom';

interface Meeting {
    id: string;
    meetingStartTime: string;
    visitSubject: string;
}

const SearchChats = () => {
    const [email, setEmail] = useState('');
    const [hostname, setHostName] = useState('');
    const [data, setData] = useState<Meeting[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const region = params.get('region') || 'eu';

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        try {
            const apiUrl = `${config.API_BASE_URL.slice(0, -6)}/v2/signalr/guest/GetBotVisitIds?email=${encodeURIComponent(email)}&hostname=${encodeURIComponent(hostname)}`;
            const response = await fetch(apiUrl, { headers: { 'X-Region': region } });
            if (!response.ok) {
                throw new Error('No meetings found');
            }
            const data = await response.json();
            setData(data);
            setError(null);
        } catch (error) {
            setData([]);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const baseAppUrl = window.location.origin;

    const formatMeetingStartTime = (isoString: string) => {
        const date = new Date(isoString);
        return date.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric'
        });
    };

    return (
        <div style={styles.container as CSSProperties}>
            <h1 style={styles.header as CSSProperties}>Find host chat link</h1>
            <form onSubmit={handleSubmit} style={styles.form}>
                <Stack tokens={{ childrenGap: 10 }} styles={{ root: { width: '100%' } }}>
                    <TextField
                        label="Guest Email used for invitation"
                        type="email"
                        value={email}
                        onChange={(e, newValue) => setEmail(newValue || '')}
                        required
                        styles={styles.textField}
                    />
                    <TextField
                        label="Host full name"
                        type="text"
                        value={hostname}
                        onChange={(e, newValue) => setHostName(newValue || '')}
                        required
                        styles={styles.textField}
                    />
                    <Stack.Item>
                        <PrimaryButton type="submit" styles={styles.button}>Search</PrimaryButton>
                    </Stack.Item>
                </Stack>
            </form>
            {loading && <Spinner label="Loading..." />}
            {error && <div style={styles.error as CSSProperties}>Error: {error}</div>}
            {data.length > 0 && (
                <div style={styles.meetingsContainer}>
                    <h2 style={styles.meetingsHeader as CSSProperties}>Meetings</h2>
                    <DetailsList
                        items={data}
                        columns={[
                            { key: 'meetingStartTime', name: 'Time', fieldName: 'meetingStartTime', minWidth: 40, maxWidth: 60, onRender: (item: Meeting) => formatMeetingStartTime(item.meetingStartTime) },
                            { key: 'meetingSubject', name: 'Subject', fieldName: 'meetingSubject', minWidth: 100, maxWidth: 200, onRender: (item: Meeting) => item.visitSubject || 'No Subject' },
                            { key: 'id', name: 'Chats', fieldName: 'id', minWidth: 100, maxWidth: 200, onRender: (item: Meeting) => <Link href={`${baseAppUrl}/chat?id=${item.id}&region=${region}&time=${formatMeetingStartTime(item.meetingStartTime)}&subject=${item.visitSubject || 'No Subject'}&host=${hostname}`} target="_blank">Chat now</Link> },
                        ]}
                        selectionMode={SelectionMode.none}
                        styles={styles.detailsList}
                    />
                    {data.some(meeting => new Date(meeting.meetingStartTime).getTime() - new Date().getTime() <= 30 * 60 * 1000) && (
                        <p style={{ ...styles.note, textAlign: 'center' }}>Chats are available only for upcoming meetings in 30 minutes</p>
                    )}
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '800px',
        margin: 'auto',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    header: {
        textAlign: 'center',
        marginBottom: '1rem',
        fontSize: '1.5rem',
    },
    form: {
        width: '100%',
    },
    textField: {
        root: {
            width: '100%',
        }
    },
    button: {
        root: {
            width: '100%',
            marginTop: '10px',
        }
    },
    error: {
        color: 'red',
        marginTop: '1rem',
        textAlign: 'center',
    },
    meetingsContainer: {
        marginTop: '1rem',
    },
    meetingsHeader: {
        textAlign: 'center',
        fontSize: '1.2rem',
    },
    detailsList: {
        root: {
            width: '100%',
            '@media (max-width: 600px)': {
                '.ms-DetailsRow-cell': {
                    flex: '1 1 auto',
                    minWidth: 'unset',
                },
            },
        },
    },
    note: {
        marginTop: '1rem',
        textAlign: 'center',
    }
};

export default SearchChats;
